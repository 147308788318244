import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Shuffle from "shuffle-array";

import { Animation } from "@common/Animations";
import { Section, Container, Tagline, SectionTitle } from "@components/global";

const TEAM = [{
    name: "Francisco Facal",
    image: "pato.jpg",
    role: ""
  },
  {
    name: "Andrés Belilos",
    image: "andy.jpg",
    role: ""
  },
  {
    name: "Santiago Rulfi",
    image: "santi.jpg",
    role: ""
  },
  {
    name: "Diego Miola",
    image: "diego.jpg",
    role: ""
  },
  {
    name: "Gastón Schattenhofer",
    image: "gasti.jpg",
    role: ""
  },
  {
    name: "Facundo Gadda",
    image: "facundo.png",
    role: ""
  },
  {
    name: "Alejo Akerfeld",
    image: "alejo.jpg",
    role: ""
  },
  {
    name: "Tomás Cortizas",
    image: "tomas.jpg",
    role: ""
  },
  {
    name: "Nicolás Davite",
    image: "nicolas.png",
    role: ""
  },
  {
    name: "Macarena Villa",
    image: "maca.jpg",
    role: ""
  },
  {
    name: "Matias Bloisi",
    image: "matias.jpg",
    role: ""
  },
  {
    name: "Branko Beros",
    image: "branko.jpg",
    role: ""
  },
  {
    name: "Marcelo Acevedo",
    image: "marcelo.jpg",
    role: ""
  },
  {
    name: "Federico Battcock",
    image: "federico.jpg",
    role: ""
  },
  {
    name: "Matias Hurtado",
    image: "matiash.png",
    role: ""
  },
];

const Team = () => ( <
  StaticQuery query = { graphql `
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 216) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        art_team: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "team_work" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    ` }
  render = {
    data => ( <
      Section id = "team"
      accent = "secondary" >
      <
      Container style = {
        { position: "relative" } } >
      <
      Animation >
      <
      Tagline > TEAM < /Tagline> <
      SectionTitle > Curious by nature. < /SectionTitle> <
      /Animation>

      <
      TeamGrid > {
        Shuffle(TEAM).map(({ name, image, role }) => {
          const img = data.allFile.edges.find(
            ({ node }) => node.relativePath === image
          ).node;

          return ( <
            div >
            <
            Img fluid = { img.childImageSharp.fluid }
            alt = { name }
            /> <
            Title > { name } < /Title> <
            Subtitle > { role } < /Subtitle> <
            /div>
          );
        })
      } <
      /TeamGrid> <
      Art >
      <
      Img fluid = { data.art_team.childImageSharp.fluid }
      /> <
      /Art> <
      ArtMobile >
      <
      Img fluid = { data.art_team.childImageSharp.fluid }
      /> <
      /ArtMobile> <
      /Container> <
      /Section>
    )
  }
  />
);

const TeamGrid = styled.div `
  display: grid;
  grid-template-columns: repeat(auto-fill, 180px);
  grid-template-rows: min-content;
  grid-gap: 50px;
  justify-content: space-between;
  width: 60%;
  margin-top: 52px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

const Art = styled.figure `
  width: 60%;
  margin: 80px 0;
  position: absolute;
  top: 0%;
  left: 60%;
  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 20%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const ArtMobile = styled.figure `
  width: 100%;
  margin: 0;
  display: none;
  margin-top: 64px;
  margin-bottom: -20%;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`;

const Title = styled.p `
  margin-top: 1em;
  ${props => props.theme.font_size.xsmall};
  color: ${props => props.theme.color.black.regular};
  font-family: ${props => props.theme.font.primary};
`;

const Subtitle = styled.p `
  color: ${props => props.theme.color.black.light};
`;

export default Team;
