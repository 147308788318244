import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { Animation } from "@common/Animations";

import Img from "gatsby-image";

import { Section, Container, Tagline, SectionTitle } from "@components/global";

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      art_fast: file(sourceInstanceName: { eq: "art" }, name: { eq: "fast" }) {
        childImageSharp {
          fluid(maxWidth: 760) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      art_learn: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "learn_yourself" }
      ) {
        childImageSharp {
          fluid(maxWidth: 760) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      art_ideas: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "ideas" }
      ) {
        childImageSharp {
          fluid(maxWidth: 760) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <Section id="services">
      <Container>
        <Animation>
          <Tagline>SERVICES</Tagline>
          <SectionTitle>We develop unique digital products.</SectionTitle>
        </Animation>
        <Grid inverse>
          <Art>
            <Img fluid={data.art_fast.childImageSharp.fluid} />
          </Art>
          <div>
            <h2>Product Development</h2>
            <p>
              We build user-centered digital products following a true agile
              practice.
            </p>
            <ul>
              <li>Rapid prototyping</li>
              <li>Wireframing</li>
              <li>User Experience (UX) Design </li>
              <li>Interface Design</li>
              <li>User Flow Diagrams</li>
              <li>Living Style Guides</li>
            </ul>
          </div>
        </Grid>
        <Grid>
          <div>
            <h2>Business Analytics & AI</h2>
            <p>
              We collect, predict and cluster business data to empower our
              clients decision making process.
            </p>
            <ul>
              <li>Web Analytics</li>
              <li>ETL Automation</li>
              <li>E-commerce Analytics</li>
              <li>Machine Learning</li>
              <li>Data Visualization</li>
              <li>Data Integrations</li>
            </ul>
          </div>
          <Art>
            <Img fluid={data.art_learn.childImageSharp.fluid} />
          </Art>
        </Grid>
        <Grid inverse>
          <Art>
            <Img fluid={data.art_ideas.childImageSharp.fluid} />
          </Art>
          <div>
            <h2>Software Development</h2>
            <p>
              Whether you are improving an existing site or app, planning a big
              migration, tuning for SEO performance, expanding your digital
              portfolio, or requiring a multilingual CMS from scratch, we can
              help you out.
            </p>
            <ul>
              <li>React Native </li>
              <li>GatsbyJS</li>
              <li>Drupal</li>
              <li>Wordpress</li>
              <li>NodeJS</li>
              <li>Python / R</li>
              <li>Google Firebase</li>
              <li>ElectronJS</li>
            </ul>
          </div>
        </Grid>
      </Container>
    </Section>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 1.6rem;
  }

  p {
    ${props => props.theme.font_size.small}
    font-family: ${props => props.theme.font.primary};
    font-weight: 400;

  }

  ul {
    margin-top: 2em;
    display: grid;
    font-family: ${props => props.theme.font.primary};
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    list-style: none;
    padding-left: 0;
    ${props => props.theme.font_size.xsmall}
    color: ${props => props.theme.color.black.light};


    @media (max-width: ${props => props.theme.screen.md}) {
      grid-template-columns: 1fr;
      list-style-type: square;
      padding-left: 2em;
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
