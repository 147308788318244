import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Animation } from "@common/Animations";

import { Container } from "@components/global";
import ExternalLink from "@common/ExternalLink";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      art_build: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "build" }
      ) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <HeaderWrapper>
      <Container>
        <Grid>
          <Art>
            <Img fluid={data.art_build.childImageSharp.fluid} />
          </Art>
          <Text>
            <Animation>
              <h2>Where technology meets storytelling.</h2>
              <p>
                We offer a full product development and engineering service,
                working close to founders and teams to help them create, develop
                and launch their ventures.
              </p>
            </Animation>
          </Text>
        </Grid>
      </Container>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  padding: 80px 0 0 0;
  color: ${props => props.theme.color.white.regular};
  text-align: center;
  h1 {
    font-weight: 300;
    margin-bottom: 0.5em;
  }
  p {
    ${props => props.theme.font_size.medium};
    color: ${props => props.theme.color.white.dark};
  }
  @media (min-width: ${props => props.theme.screen.lg}) {
    padding: 0px;
  }
`;

const Art = styled.figure`
  width: 100%;
  margin: 0;

  > div {
    width: 100%;

    @media (min-width: ${props => props.theme.screen.lg}) {
      width: 110%;
      margin-top: 100px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 0px;
  min-height: 100vh;
  > ${Art} {
    order: 2;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 2fr 2fr;
    grid-gap: 0px;
    align-items: top;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    grid-template-columns: 2fr 1fr;
  }

  > ${Art} {
    order: 2;
  }
`;

const Text = styled.div`
  justify-self: center;
  padding-top: 100px;
  font-family: ${props => props.theme.font.primary};

  h2 {
    ${props => props.theme.font_size.larger};
    font-weight: 700;
    @media (min-width: ${props => props.theme.screen.md}) {
      ${props => props.theme.font_size.xlarge};
    }
    @media (min-width: ${props => props.theme.screen.lg}) {
      ${props => props.theme.font_size.xxlarge};
    }
  }
  p {
    ${props => props.theme.font_size.small};
    padding: 25px 0%;
    @media (min-width: ${props => props.theme.screen.lg}) {
      ${props => props.theme.font_size.regular};
    }
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.color.black.regular};
  }
`;

export default Header;
