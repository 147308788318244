import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Animation } from "@common/Animations";

import { Section, Container, Tagline, SectionTitle } from "@components/global";
import ExternalLink from "@common/ExternalLink";

import MullenLoweLogo from "@images/logos/mullenlowe.png";
import WundermanLogo from "@images/logos/wunderman.png";
import ChinatownLogo from "@images/logos/chinatown.png";
import Phase2Logo from "@images/logos/phase2.png";
import SantoLogo from "@images/logos/logosanto.png";
import Mperativ from "@images/logos/mperativ.png";
import AfaLogo from "@images/logos/afalogo.png";
import DonLogo from "@images/logos/donlogo.png";
import Digitas from "@images/logos/digitas.png";
import Candoo from "@images/logos/candoo.png";
import Kara from '@images/logos/kara.png';

const LOGOS = [
    {
    logo: Mperativ,
    link: "https://www.mperativ.io"
  },
    {
    logo: MullenLoweLogo,
    link: "https://us.mullenlowe.com/"
  },  
  {
    logo: Phase2Logo,
    link: "https://www.phase2technology.com/"
  },
  {
    logo: Kara,
    link: "https://www.visuwall.com/"
  },
  {
    logo: ChinatownLogo,
    link: "https://chinatownbureau.com/"
  },

  {
    logo: WundermanLogo,
    link: "https://www.wunderman.com/"
  },
  {
    logo: Digitas,
    link: "https://www.digitas.com/es-ar"
  },
  {
    logo: DonLogo,
    link: "https://www.donbue.com/"
  },
  {
    logo: Candoo,
    link: "https://www.candootech.com/"
  },  
  {
    logo: AfaLogo,
    link: "https://afa.org.ar/"
  }
];

const UsedBy = () => (
  <StaticQuery
    query={graphql`
      query {
        art_story: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "tell_story" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="work" accent>
        <StyledContainer>
          <div>
            <Animation>
              <Tagline style={{ color: "white" }}>OUR CLIENTS</Tagline>
              <SectionTitle>
                We're better together
              </SectionTitle>
            </Animation>

            <LogoGrid>
              {LOGOS.map(({ logo, link }) => (
                <ExternalLink href={link}>
                  <img src={logo} alt={link} />
                </ExternalLink>
              ))}
            </LogoGrid>
          </div>
          <Art>
            <Img fluid={data.art_story.childImageSharp.fluid} />
          </Art>
        </StyledContainer>
      </Section>
    )}
  />
);

const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-items: center;
  text-align: center;
  margin-top: 20px;
  img {
    width: 75%;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  position: relative;
  color: ${props => props.theme.color.white.regular};
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
    min-height: 1250px;
  }
`;

const Art = styled.figure`
  width: 600px;
  position: absolute;
  top: 0%;
  right: 50%;

  @media (max-width: ${props => props.theme.screen.md}) {
    top: 560px;
    left: 10%;
    width: 70%;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    top: 960px;
    left: -10%;
    width: 100%;
  }
`;

export default UsedBy;
