import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Spring } from "react-spring/renderprops";

export const Animation = ({ children }) => (
  <VisibilitySensor>
    {({ isVisible }) => (
      <Spring
        delay={80}
        offset={500}
        to={{
          opacity: isVisible ? 1 : 0,
          transform: isVisible
            ? "translate3d(0,-20px,0)"
            : "translate3d(0,0px,0)"
        }}
      >
        {props => <div style={props}>{children}</div>}
      </Spring>
    )}
  </VisibilitySensor>
);
