import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Container } from "@components/global";
import ExternalLink from "@common/ExternalLink";

import GithubIcon from "@static/icons/github.svg";
import InstagramIcon from "@static/icons/instagram.svg";
import TwitterIcon from "@static/icons/twitter.svg";

const SOCIAL = [
  {
    icon: GithubIcon,
    link: "https://github.com/ajayns/gatsby-absurd"
  },
  {
    icon: InstagramIcon,
    link: "https://instagram.com/ajay_ns"
  },
  {
    icon: TwitterIcon,
    link: "https://twitter.com/ajayns08"
  }
];

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        art_pot: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "customers_pot" }
        ) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <FooterWrapper>
          <StyledContainer>
            <Copyright>
              <h2>|> QUUACK</h2>
              <div className="guido">Happily independent since &copy;2018</div>
            </Copyright>
            <div className="guido">
              Illustrations by
              <br />
              <ExternalLink href="https://www.guidoferro.com/">
                <u>Guido Ferro</u>
              </ExternalLink>
            </div>
          </StyledContainer>
        </FooterWrapper>
      </React.Fragment>
    )}
  />
);

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.primary};
  padding: 32px 0;

  div.guido {
    color: ${props => props.theme.color.white.regular};
    font-family: ${props => props.theme.font.primary};
    text-align: right;
    padding-left: 3px;
    ${props => props.theme.font_size.xsmall};
    a {
      text-decoration: none;
      color: inherit;
    }
    @media (max-width: ${props => props.theme.screen.sm}) {
      margin-top: 1.5em;
      ${props => props.theme.font_size.xsmall};
      text-align: center;
    }
  }
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.primary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.white.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Art = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 48px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5em;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export default Footer;
